<template>
  <div>
    <div class="row pb-4  df">
      <h2 class="col m-1">{{ $t('sensors') }}</h2>
      <span class="mt-2 mb-2 mr-5 btn btn-sm  btn-secondary d-lg-none" @click="showCreateSensor">+
        {{ $t('add') }}</span>

      <div class="col-md-auto m-1">
        <input id="table-filter" ref="filter" type="text" class="form-control" :placeholder="$t('filterTable')"
          @keyup="filterTable" />
      </div>
    </div>
    <div class="tabe-responsive">
      <table ref="table" class="table-style table-settings table_sort">
        <thead>
          <tr>
            <th>{{ $t('sensorId') }}</th>
            <th>{{ $t('sensorType') }}</th>
            <th>{{ $t('lastMessage') }}</th>
            <th class="text-center">
              X / Y
            </th>
            <th>{{ $t('room') }} / {{ $t('desk') }}</th>
            <th v-if="isDashboardAdmin">
              <span class="btn btn-secondary" @click="showCreateSensor">+ {{ $t('add') }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(sensor, index) in sensors">
            <tr :key="sensor.sensorId" :data-val="sensor.sensorId + sensor.sensorType">
              <td :data-label="$t('sensorId') + ':'">
                {{ sensor.sensorId }}
              </td>
              <td :data-label="$t('sensorType')">
                {{ sensor.sensorType }}
              </td>
              <td :data-label="$t('lastMessage') + ':'">
                {{ sensor.datetime | moment('MMM Do YYYY, H:mm') }}
              </td>
              <td data-label="X / Y" class="text-center">
                {{ sensor.x ? Number(sensor.x).toFixed(2) : '-' }}
                /
                {{ sensor.y ? Number(sensor.y).toFixed(2) : '-' }}
              </td>
              <td :data-label="$t('room') + ' / ' + $t('desk') + ':'">
                {{ sensor.parentName }}
              </td>
              <td v-if="isDashboardAdmin" data-label>
                <span class="link-edit text-primary" @click="showUpdateSensor(index)">
                  <font-awesome-icon icon="edit" />Edit
                </span>
                |
                <span class="link-remove" @click="
                    ; (selectedIndex = index),
        $bvModal.show('modal-remove')
        ">
                  <font-awesome-icon icon="times" />Remove
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <b-modal id="modal-remove" :on-enter-key-press="() => removeSensor(selectedIndex)" hide-footer>
      <template v-slot:modal-title>
        <strong>{{ $t('remove') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('deleteConfirmation') }}</p>
        <b-button variant="danger" class="mt-1" @click="$bvModal.hide('modal-remove')">
          {{ $t('no') }}
        </b-button>
        <b-button class="mt-1 ml-1" primary @click="removeSensor(selectedIndex)">
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
    <b-modal id="modal-add-or-edit" :on-enter-key-press="upsertSensor" hide-footer>
      <template v-slot:modal-title>
        <strong>{{ mode === 'create' ? $t('add') : $t('edit') }}
          {{ $t('sensor') }}</strong>
      </template>
      <div class="d-block text-center">
        <div class="row">
          <div class="col-6 text-left">
            <div class="form-group">
              <label>{{ $t('sensorId') }}:</label>
              <input v-model="selectedSensor.sensorId" type="text" :readonly="mode !== 'create'" class="form-control" />
              <b-form-invalid-feedback v-if="errors.sensorId">
                {{ errors['sensorId'] }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-6 text-left">
            <div class="form-group">
              <label>{{ $t('sensorExternalId') }}:</label>
              <input v-model="selectedSensor.externalId" type="text" class="form-control" />
              <b-form-invalid-feedback v-if="errors.externalId">
                {{ errors['externalId'] }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-12 text-left">
            <div class="form-group">
              <label>{{ $t('sensorType') }}:</label>
              <b-form-select v-model="selectedSensor.sensorType">
                <option :value="null">
                  {{ $t('selectOption') }}
                </option>
                <option v-for="(sensorType, index) in sensorTypes" :key="index" :value="sensorType">
                  {{ sensorType }}
                </option>
              </b-form-select>
              <b-form-invalid-feedback v-if="errors.sensorType">
                {{ errors['sensorType'] }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-12 text-left">
            <div class="form-group">
              <label>{{ $t('room') }} / {{ $t('desk') }}:</label>
              <model-list-select v-model="selectedSensor.objectId" :list="roomDeskList" option-value="id"
                option-text="name" :placeholder="$t('selectOption')" class="custom-select" @searchchange="
        selectedSensor.parentName = getParentName(
          selectedSensor.objectId
        )
        " @select="
        selectedSensor.parentName = getParentName(
          selectedSensor.objectId
        )
        " />
              <b-form-invalid-feedback v-if="errors.objectId">
                {{ errors['objectId'] }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-6 text-left">
            <label>X:</label>
            <input v-model="selectedSensor.x" type="number" class="form-control" />
            <b-form-invalid-feedback v-if="errors.x">
              {{ errors['x'] }}
            </b-form-invalid-feedback>
          </div>
          <div class="col-6 text-left">
            <label>Y:</label>
            <input v-model="selectedSensor.y" type="number" class="form-control" />
            <b-form-invalid-feedback v-if="errors.y">
              {{ errors['y'] }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div v-if="['okkuCO2', 'okku-room', 'okku-sound'].includes(selectedSensor.sensorType)" class="row mt-3">
          <div class="col-6 text-left">
            <label>Occupied Threshold CO2 (ppm):</label>
            <input v-model="selectedSensor.occupiedThresholdCO2" type="number" class="form-control" placeholder="700" />
            <b-form-invalid-feedback v-if="errors.occupied_threshold_co2">
              {{ errors['occupied_threshold_co2'] }}
            </b-form-invalid-feedback>
          </div>
          <div class="col-6 text-left">
            <label>Occupied Threshold Motion (per time interval):</label>
            <input v-model="selectedSensor.occupied_threshold_motion" type="number" class="form-control"
              placeholder="0" />
            <b-form-invalid-feedback v-if="errors.occupied_threshold_motion">
              {{ errors['occupied_threshold_motion'] }}
            </b-form-invalid-feedback>
          </div>

        </div>
        <br />
        <b-button variant="danger" class="mt-1" @click="cancelUpsert">
          {{ $t('cancel') }}
        </b-button>
        <b-button class="mt-1 ml-1" primary @click="upsertSensor">
          {{ $t('save') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { cloneDeep as _cloneDeep, each as _each } from 'lodash'
import Vue from 'vue'
import { mapState } from 'vuex'
import vueMoment from 'vue-moment'
import { ModelListSelect } from 'vue-search-select'

import { filterTableMixin } from '@/mixins/mixins'
import OkkuApi from '@/services/OkkuApi'

import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

import 'vue-search-select/dist/VueSearchSelect.css'
import { uuidv4 } from '@/services/Helper'

Vue.use(vueMoment)

export default {
  name: 'SensorsTable',
  components: {
    ModelListSelect,
    'b-modal': BootstrapModalHOC
  },
  mixins: [filterTableMixin],
  data() {
    return {
      sensors: [],
      selectedSensor: {},
      sensorTypes: [],
      selectedIndex: null,
      changesDone: false,
      mode: 'create',
      errors: {}
    }
  },
  computed: {
    ...mapState('common', {
      organisation: '$organisation',
      isDashboardAdmin: '$isDashboardAdmin',
      isCustomerAdmin: '$isCustomerAdmin'
    }),
    ...mapState('common', ['rooms', 'desks']),
    roomDeskList() {
      return [].concat(this.rooms, this.desks)
    }
  },
  watch: {
    organisation() {
      this.getSensors()
      this.getSensorTypes()
    }
  },
  mounted() {
    if (this.organisation) {
      this.getSensors()
      this.getSensorTypes()
    }
  },
  methods: {
    updateChanges() {
      this.$emit('updateChanges', this.changesDone)
    },
    updateAlert(variant, text) {
      this.$emit('updateAlert', { variant, text })
    },
    getSensors() {
      OkkuApi.getSensors().then(sensors => {
        this.sensors = []
        _each(sensors, sensor => {
          this.sensors.push({
            sensorId: sensor.sensor_id,
            sensorType: sensor.sensor_type,
            x: sensor.x,
            y: sensor.y,
            objectId: sensor.wmt_object_id,
            datetime: sensor.datetime,
            externalId: sensor.sensor_external_id,
            parentName: this.getParentName(sensor.wmt_object_id),
            occupiedThresholdCO2: sensor.occupied_threshold_co2,
            occupiedThresholdMotion: sensor.occupied_threshold_motion
          })
        })
      })
    },
    getSensorTypes() {
      OkkuApi.getSensorTypes().then(sensorTypes => {
        this.sensorTypes = sensorTypes
      })
    },
    showCreateSensor() {
      this.mode = 'create'
      this.selectedIndex = null
      this.selectedSensor = {
        sensorId: '',
        sensorType: '',
        x: null,
        y: null,
        objectId: null,
        externalId: null
      }
      this.$bvModal.show('modal-add-or-edit')
    },
    showUpdateSensor(index) {
      this.mode = 'update'
      this.selectedIndex = index
      this.selectedSensor = _cloneDeep(this.sensors[index])
      this.$bvModal.show('modal-add-or-edit')
    },
    cancelUpsert() {
      this.$bvModal.hide('modal-add-or-edit')
      this.selectedIndex = null
      this.selectedSensor = {}
      this.errors = {}
    },
    getParentName(wmtObjectId) {
      const deskName = this.desks.find(
        desk => desk.id === wmtObjectId
      )?.name
      const roomName = this.rooms.find(
        room => room.id === wmtObjectId
      )?.name
      return deskName ?? roomName ?? '-'
    },
    upsertSensor() {
      this.selectedSensor.parentName = this.getParentName(
        this.selectedSensor.objectId
      )
      if (!this.isSensorDataValidate()) {
        return
      }
      const { x, y, externalId, occupiedThresholdCO2, occupiedThresholdMotion } = this.selectedSensor

      if (!x) this.selectedSensor.x = 0
      if (!y) this.selectedSensor.y = 0

      if (!externalId) this.selectedSensor.externalId = uuidv4()

      const {
        sensorId,
        objectId,
        sensorType,
        x: xCoordinate,
        y: yCoordinate,
        externalId: sensorExternalId
      } = this.selectedSensor

      if (this.mode === 'create') {
        const sensor = {
          sensor_id: sensorId,
          wmt_object_id: objectId,
          x: xCoordinate,
          y: yCoordinate,
          sensor_type: sensorType,
          sensor_external_id: sensorExternalId,
          occupied_threshold_co2: occupiedThresholdCO2,
          occupied_threshold_motion: occupiedThresholdMotion
        }
        OkkuApi.createSensor([sensor])
          .then(response => {
            this.updateAlert('success', this.$t('changesSaved'))
            this.sensors.unshift(this.selectedSensor)
            this.$bvModal.hide('modal-add-or-edit')
          })
          .catch(error => {
            this.updateAlert('danger', error.message)
          })
      } else {
        const sensor = {
          sensor_id: sensorId,
          wmt_object_id: objectId,
          x: xCoordinate,
          y: yCoordinate,
          sensor_type: sensorType,
          sensor_external_id: sensorExternalId,
          occupied_threshold_co2: occupiedThresholdCO2,
          occupied_threshold_motion: occupiedThresholdMotion
        }
        OkkuApi.updateSensor([sensor])
          .then(response => {
            this.errors = {}
            this.updateAlert('success', this.$t('changesSaved'))
            this.sensors[this.selectedIndex] = this.selectedSensor
            this.$forceUpdate()
            this.$bvModal.hide('modal-add-or-edit')
          })
          .catch(error => {
            this.updateAlert('danger', error.message)
          })
      }
    },
    isSensorDataValidate() {
      const { sensorId, sensorType } = this.selectedSensor

      if (!sensorId) {
        this.errors = { sensorId: 'Please enter sensor id ' }
        return false
      }

      if (!sensorType) {
        this.errors = { sensorType: 'Please select sensor type' }
        return false
      }

      return true
    },
    removeSensor() {
      OkkuApi.deleteSensor(this.sensors[this.selectedIndex].sensorId)
        .then(response => {
          this.updateAlert('success', response)
          this.$bvModal.hide('modal-remove')
          this.sensors.splice(this.selectedIndex, 1)
          this.$forceUpdate()
          this.selectedIndex = null
        })
        .catch(error => {
          this.updateAlert('danger', error.message)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.tip-icon::v-deep {
  .fa-question-circle {
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
}

table {
  input {
    display: inline-block;
    vertical-align: middle;
  }
}

.ui.dropdown::v-deep {
  color: #495057 !important;
  border: 1px solid #ced4da !important;
  display: inline-block !important;
  height: 38px;
  font-size: 16px;
  white-space: nowrap !important;
  padding: 11px 10px !important;
  border-radius: 4px !important;

  .dropdown.icon {
    margin-right: -11px !important;
    padding: 10px 7px !important;
    background-color: #fff;
    z-index: 4 !important;
    position: relative !important;
    top: 0 !important;
    opacity: 1 !important;
    right: 2px !important;
  }

  .menu {
    border-color: #ced4da !important;
  }

  .item.item.item.item {
    padding: 10px !important;
  }
}

.invalid-feedback {
  display: inline;
}
</style>
